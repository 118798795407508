// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-fehler-melden-tsx": () => import("./../../../src/pages/fehler-melden.tsx" /* webpackChunkName: "component---src-pages-fehler-melden-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-shop-melden-tsx": () => import("./../../../src/pages/shop-melden.tsx" /* webpackChunkName: "component---src-pages-shop-melden-tsx" */),
  "component---src-pages-shopoverview-tsx": () => import("./../../../src/pages/shopoverview.tsx" /* webpackChunkName: "component---src-pages-shopoverview-tsx" */),
  "component---src-templates-store-template-tsx": () => import("./../../../src/templates/store_template.tsx" /* webpackChunkName: "component---src-templates-store-template-tsx" */)
}

